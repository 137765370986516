a:hover {
  cursor: pointer !important;
}

.Hover:hover {
  cursor: pointer!important;
}

.Hover:hover .ContextMenu:hover {
  cursor: context-menu!important;
}

.Link a {
  text-decoration: underline;
}

.Disabled:hover {
  cursor: not-allowed !important;
}

.DisableSelection,
.DisableSelection > * {
  user-select: none!important;
  -ms-user-select: none!important;
  -moz-user-select: none!important;
  -khtml-user-select: none!important;
  -webkit-user-select: none!important;
  -webkit-touch-callout: none!important;
}

/* Blurred Background workaround */
.Blur {
  background: rgba(255, 255, 255, 0.993);
}

@supports (backdrop-filter: blur(30px)) {
  .Blur {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
}

/* HeaderLink hover */
.HeaderLink::after {
  transition: all 0.3s ease-in-out;
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 2px;
  bottom: 0px;
  content: "";
  background-color: var(--colors-BLUE);
}

.HeaderLink:hover::after {
  width: 100%;
}

/* Range Input */
input[type="range"] {
  -webkit-appearance: none;
  background: var(--colors-WHITE);
  background-image: linear-gradient(to left, var(--colors-GREEN), var(--colors-GREEN));
	background-repeat: no-repeat;
  border: 2px solid var(--colors-MEDIUMWHITE);
  border-radius: 10px;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 10px var(--colors-DARKWHITE);
  border: 2px solid var(--colors-DARKWHITE);
  height: 23px;
  width: 23px;
  border-radius: 25px;
  background: var(--colors-WHITE);
  cursor: pointer;
  margin-top: -4px;
}
input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  height: 15px;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 1px 1px 10px var(--colors-DARKWHITE);
  border: 2px solid var(--colors-DARKWHITE);
  height: 23px;
  width: 23px;
  border-radius: 25px;
  background: var(--colors-WHITE);
  cursor: pointer;
  margin-top: -4px;
}
input[type="range"]::-moz-range-track {
  background: none;
  border: none;
  box-shadow: none;
  height: 15px;
}
input[type=range]:focus {
  outline: none;
}

/* ScrollDownArrow */
.ScrollDownArrow:after {
  content: '';
  display: block;
  margin: 0;
  padding: 0;
  width: 8;
  height: 8px;
  border-top: 2px solid var(--colors-BLUE);
  border-right: 2px solid var(--colors-BLUE);
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.ScrollDownLine:after {
  -webkit-animation: scrollDownLine 2.5s cubic-bezier(1, 0, 0, 1) infinite;
  -moz-animation: scrollDownLine 2.5s cubic-bezier(1, 0, 0, 1) infinite;
  -o-animation: scrollDownLine 2.5s cubic-bezier(1, 0, 0, 1) infinite;
  animation: scrollDownLine 2.5s cubic-bezier(1, 0, 0, 1) infinite;
  position: absolute;
  top: 30px;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 60px;
  background: var(--colors-BLUE);
  content: '';
}
@-webkit-keyframes scrollDownLine {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@-moz-keyframes scrollDownLine {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@-o-keyframes scrollDownLine {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}
@keyframes scrollDownLine {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}

/* Loader Spinner */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
